<script setup lang="ts">
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
  ExclamationIcon,
} from '@heroicons/vue/outline/index.js';
import type { severity } from '~/interfaces/dto/general/toast-messages';

interface IErrorProps {
  state?: severity;
}
const props = withDefaults(defineProps<IErrorProps>(), {
  state: 'danger',
});
const determineBorder = computed<string>((): string => {
  switch (props.state) {
    case 'success':
      return 'b-success-500';
    case 'info':
      return 'b-primary-blue-700';
    case 'danger':
      return 'b-error-500';
    case 'warning':
      return 'b-yellow-500';
    default:
      return 'b-primary-blue-700';
  }
});
const determineIconBg = computed<string>((): string => {
  switch (props.state) {
    case 'success':
      return 'bg-success-500';
    case 'info':
      return 'blue-gradient text-white';
    case 'danger':
      return 'bg-error-500';
    case 'warning':
      return 'bg-yellow-500';
    default:
      return 'blue-gradient';
  }
});
const determineIcon = computed<any>((): any => {
  switch (props.state) {
    case 'success':
      return CheckCircleIcon;
    case 'info':
      return ExclamationCircleIcon;
    case 'danger':
      return XCircleIcon;
    case 'warning':
      return ExclamationIcon;
    default:
      return CheckCircleIcon;
  }
});

const genericResponse = ref(null);

onMounted(() => {
  genericResponse.value.scrollIntoView();
});
</script>
<template>
  <div
    class="mx-auto w-full h-full"
    ref="genericResponse"
    style="max-width: 320px"
  >
    <div
      class="h-full flex justify-content-start align-items-center border-1 dark:text-white text-black text-xs p-2 text-left border-round-md mt-2"
      :class="[determineBorder]"
    >
      <div
        :class="[determineIconBg]"
        class="w-6 h-6 flex border-round-sm align-items-center justify-content-center mr-2"
      >
        <component :is="determineIcon" :key="props.state" class="w-5" />
      </div>
      <slot />
    </div>
  </div>
</template>
